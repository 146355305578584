import React, { useState, useEffect } from 'react';
import './feedback.css';
import { Link } from 'react-router-dom';

export default function Feedback() {
  return (
    <div>
      <div className='feedback-align'>
        <Link to='/feedback'>
          <div className='feedback'>
            <p className='feedback-text'> File a Complaint</p>
          </div>
        </Link>
      </div>
    </div>
  )
}
