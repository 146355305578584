// import React, { useState, useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
// import { Col, Container, Row } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import './feedbackpage.css';
// import CaptchaTest from './CaptchaTest';

// export default function FeedbackPage() {
//   return (
//     <div>
//        <Container fluid className='right-to-information' >
//                 <Row>
//                     <Col md={12}>
//                         <div className="organisation-img">
//                             <h1 className='organisation-head'>Feedbacks</h1>
//                         </div>
//                     </Col>
//                 </Row>
//             </Container>
//          <Container >
//           <div className='form-feedback'>
//           <Form>
//           <Row>
//         <Form.Group>
//           <Form.Label>Full Name</Form.Label>
//         <Form.Control type="text" placeholder="Full name" id="feedback-input"/>
//         </Form.Group>
//         <Form.Group as={Col} controlId="formGridEmail" className='mt-3'>
//           <Form.Label>Email</Form.Label>
//           <Form.Control type="email" placeholder="Enter email" id="feedback-input" />
//         </Form.Group>
//       <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
//         <Form.Label>Address</Form.Label>
//         <Form.Control as="textarea" rows={3} placeholder='Enter Address' id="feedback-input" />
//       </Form.Group>
//    <Form.Group className='mt-3'>
//        <Form.Label>Select</Form.Label >
//       <Form.Select aria-label="Default select example" id="feedback-input">
//       <option>Open this select menu</option>
//       <option value="1">Reporting a problem</option>
//       <option value="2">Suggestions</option>
//       <option value="3">Complaints</option>
//       <option value="4">User Experience</option>
//       <option value="5">Compliments</option>
//       <option value="6">Others</option>
//     </Form.Select>
//    </Form.Group>
//       <Form.Group controlId="formFile" className="mt-3" >
//         <Form.Label>Upload File</Form.Label>
//         <Form.Control type="file" id="feedback-input" />
//       </Form.Group>
//       <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1" >
//         <Form.Label>Remark</Form.Label>
//         <Form.Control as="textarea" rows={3} placeholder='Remarks...' id="feedback-input" />
//       </Form.Group>
//       <CaptchaTest/>
//       </Row>
//       <Button variant="primary" type="submit" className="mt-3">
//         Submit
//       </Button>
//     </Form>
//           </div>
//          </Container>
//     </div>
//   )
// }
// import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import { Col, Container, Row } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import './feedbackpage.css';
// import CaptchaTest from './CaptchaTest';

// export default function FeedbackPage() {
//   const [formData, setFormData] = useState({
//     fullName: '',
//     email: '',
//     address: '',
//     type: '',
//     remarks: '',
//     file: null,
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleFileChange = (e) => {
//     setFormData({
//       ...formData,
//       file: e.target.files[0],
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const formDataToSend = new FormData();
//     formDataToSend.append('fullName', formData.fullName);
//     formDataToSend.append('email', formData.email);
//     formDataToSend.append('address', formData.address);
//     formDataToSend.append('type', formData.type);
//     formDataToSend.append('remarks', formData.remarks);
//     formDataToSend.append('file', formData.file);

//     try {
//       const response = await fetch('https://dev.erckerala.org/api/feedback', {
//         method: 'POST',
//         body: formDataToSend,
//       });

//       if (response.ok) {
//         alert('Feedback submitted successfully!');
//       } else {
//         alert('Failed to submit feedback');
//       }
//     } catch (error) {
//       console.error('Error submitting feedback:', error);
//       alert('Error submitting feedback');
//     }
//   };

//   return (
//     <div>
//       <Container fluid className="right-to-information">
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className="organisation-head">File a Complaint</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <Container>
//         <div className="form-feedback">
//           <Form onSubmit={handleSubmit}>
//             <Row>
//               <Form.Group>
//                 <Form.Label>Full Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Full name"
//                   name="fullName"
//                   value={formData.fullName}
//                   onChange={handleChange}
//                   id="feedback-input"
//                 />
//               </Form.Group>
//               <Form.Group as={Col} controlId="formGridEmail" className="mt-3">
//                 <Form.Label>Email</Form.Label>
//                 <Form.Control
//                   type="email"
//                   placeholder="Enter email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   id="feedback-input"
//                 />
//               </Form.Group>
//               <Form.Group as={Col} controlId="formGridPhone" className="mt-3">
//   <Form.Label>Ph Number</Form.Label>
//   <Form.Control
//     type="tel"
//     placeholder="Enter phone number"
//     name="phone"  
//     value={formData.phone}  
//     onChange={handleChange}
//     id="feedback-input"
//   />
// </Form.Group>


//               <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
//                 <Form.Label>Address</Form.Label>
//                 <Form.Control
//                   as="textarea"
//                   rows={3}
//                   placeholder="Enter Address"
//                   name="address"
//                   value={formData.address}
//                   onChange={handleChange}
//                   id="feedback-input"
//                 />
//               </Form.Group>
//               <Form.Group className="mt-3">
//                 <Form.Label>Select</Form.Label>
//                 <Form.Select
//                   aria-label="Default select example"
//                   name="type"
//                   value={formData.type}
//                   onChange={handleChange}
//                   id="feedback-input"
//                 >
//                   <option>Open this select menu</option>
//                   <option value="1">Reporting a problem</option>
//                   <option value="2">Suggestions</option>
//                   <option value="3">Complaints</option>
//                   <option value="4">User Experience</option>
//                   <option value="5">Compliments</option>
//                   <option value="6">Others</option>
//                 </Form.Select>
//               </Form.Group>
//               {/* <Form.Group controlId="formFile" className="mt-3">
//                 <Form.Label>Upload File</Form.Label>
//                 <Form.Control
//                   type="file"
//                   name="file"
//                   onChange={handleFileChange}
//                   id="feedback-input"
//                 />
//               </Form.Group> */}
//               <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
//                 <Form.Label>Remark</Form.Label>
//                 <Form.Control
//                   as="textarea"
//                   rows={3}
//                   placeholder="Remarks..."
//                   name="remarks"
//                   value={formData.remarks}
//                   onChange={handleChange}
//                   id="feedback-input"
//                 />
//               </Form.Group>
//               <CaptchaTest />
//             </Row>
//             <Button variant="primary" type="submit" className="mt-3">
//               Submit
//             </Button>
//           </Form>
//         </div>
//       </Container>
//     </div>
//   );
// }


import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import './feedbackpage.css';
import CaptchaTest from './CaptchaTest';

export default function FeedbackPage() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    street: '',
    city: '',
    district: '',
    pinCode: '',
    type: '',
    otherType: '',
    remarks: '',
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    if (
      !formData.fullName ||
      !formData.email ||
      !formData.phone ||
      !formData.address ||
      !formData.street ||
      !formData.city ||
      !formData.district ||
      !formData.pinCode ||
      !formData.type ||
      (formData.type === '5' && !formData.otherType) ||
      !formData.remarks
    ) {
      alert('Please fill out all required fields.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('fullName', formData.fullName);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('street', formData.street);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('district', formData.district);
    formDataToSend.append('pinCode', formData.pinCode);
    formDataToSend.append('type', formData.type);
    formDataToSend.append('otherType', formData.otherType || '');
    formDataToSend.append('remarks', formData.remarks);
    formDataToSend.append('file', formData.file);

    try {
      const response = await fetch('https://dev.erckerala.org/api/feedback', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        alert('Feedback submitted successfully!');
      } else {
        alert('Failed to submit feedback');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Error submitting feedback');
    }
  };

  return (
    <div>
      <Container fluid className="right-to-information">
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className="organisation-head">File a Complaint</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{ marginTop: '20px' }}>
        <div className="form-feedback">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Full name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  id="feedback-input"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail" className="mt-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  id="feedback-input"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPhone" className="mt-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter phone number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  id="feedback-input"
                  required
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Address Line</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address Line"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  id="feedback-input"
                  required
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="street">
                <Form.Label>Street</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Street"
                  name="street"
                  value={formData.street}
                  onChange={handleChange}
                  id="feedback-input"
                  required
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  id="feedback-input"
                  required
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="district">
                <Form.Label>District</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter District"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  id="feedback-input"
                  required
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="pinCode">
                <Form.Label>PIN Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter PIN Code"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={handleChange}
                  id="feedback-input"
                  required
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Nature of Complaint</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  id="feedback-input"
                  required
                >
                  <option value="">Open this select menu</option>
                  <option value="1">Complaint</option>
                  <option value="2">Petition</option>
                  <option value="3">Feedback</option>
                  <option value="4">Suggestion</option>
                  <option value="5">Others</option>
                </Form.Select>
              </Form.Group>
              {/* Conditionally render the additional input if "Others" is selected */}
              {formData.type === "5" && (
                <Form.Group className="mt-3" controlId="otherType">
                  <Form.Label>Specify Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the type"
                    name="otherType"
                    value={formData.otherType || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, otherType: e.target.value })
                    }
                    id="feedback-input"
                    required
                  />
                </Form.Group>
              )}
              <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Remark</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Remarks..."
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  id="feedback-input"
                  required
                />
              </Form.Group>
              <CaptchaTest />
            </Row>
            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
}
