import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './careerspage.css';
import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import APIService from '../../APIService';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function Careerspage() {

  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [careers, setCareers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    APIService.get('careers').then((response) => {
      setCareers(response.careers);
      setFilteredData(response.careers);
    })
  }, []);
  const formatDateYYMMDDtoDDMMYY = (dateString) => {
    if (!dateString || dateString.length < 6) return dateString; // Handle undefined or invalid formats

    let formattedDate = "";
    if (dateString.includes('-')) { 
        // Handling possible formats like "24-02-13"
        const parts = dateString.split('-');
        if (parts.length === 3) {
            formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert yymmdd -> dd-mm-yyyy
        }
    } else {
        // Handling "240213" format (yymmdd)
        const year = dateString.slice(0, 2);
        const month = dateString.slice(2, 4);
        const day = dateString.slice(4, 6);
        formattedDate = `${day}-${month}-${year}`; // Convert yymmdd -> dd-mm-yyyy
    }

    return formattedDate;
};
  
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 2001; year--) {
    years.push(year);
  }

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredItems = careers.filter((data) =>
      data.job_title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);

    const filteredItems = careers.filter((data) =>
      data.last_date.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  return (
    <div>
      <Container fluid className='career'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Careers</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{marginTop:'20px'}}>
        <Row className='wrapper'>
          <Col className='mt-2' md={4} id="archives-search-area">
            
              <Form.Select
                aria-label="Default select example"
                value={filterYear}
                onChange={handleYearFilterChange}
              >
                <option value="">Year</option>
                {years.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </Form.Select>
            
          </Col>
          <Col md={4} className='form-aling mt-2' id='archives-form'>
            <FloatingLabelSerch className='Search-aling' controlId="floatingInputGrid" label="Keyword Search..">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchItem}
                onChange={handleSearchInputChange}
              />
            </FloatingLabelSerch>
            {/* <button type="button" className="btn btn-outline-primary" id='search-btn'>Search</button> */}
          </Col>
          <Col className='mt-2' md={4} id='search-btn1'  style={{textAlign: 'center',height:'42px' }}> <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button></Col>
        </Row>
      </Container>
      <Container style={{marginTop:'10px'}}>
        <Row >
          <Col style={{ overflowX: 'auto' }} md={12} className='wrapper'>
            <Table  striped bordered hover variant="light">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th style={{width:'100px'}}>Last Date</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map(career => (
                  <tr key={career.id}>
                    <td>{career.job_title}</td>
                    <td>{career.description}</td>
                    {/* <td>{career.last_date}</td> */}
                    <td>{formatDateYYMMDDtoDDMMYY(career.last_date)}</td>

                    <td>
                      <div className='ordrs-icon-aling'>
                        <Link to={career.file} target='blank'>view</Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
